import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  max-width: 1200px;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  #logo {
    display: block;
    padding-top: 12px;
    padding-bottom: 12px;

    img {
      width: 100px;
    }

    @media only screen and (min-width: 400px) {
      img {
        width: 120px;
      }
    }

    @media only screen and (min-width: 1024px) {
      img {
        width: 200px;
      }
    }

    @media only screen and (min-width: 1280px) {
      img {
        width: 200px;
      }
    }
  }

  #barra {
    padding-right: 0px;
    display: flex;
    flex-direction: row;
    position: relative;

    .icon {
      display: flex;
      flex-direction: row;
    }

    .blococard {
      display: block;

      button {
        display: flex;
        flex-direction: row;
        background: none;
        border: 0;
        border-bottom: 1px solid #eee;
      }
    }

    .favorito {
      display: none;
    }

    @media only screen and (min-width: 375px) {
      padding-right: 0px;

      .favorito {
        display: block;
      }
    }

    @media only screen and (min-width: 1024px) {
      padding-right: 12px;

      .favorito {
        display: block;
      }
    }

    @media only screen and (min-width: 1280px) {
      padding-right: 12px;

      .favorito {
        display: block;
      }
    }

    .login {
      display: flex;
      align-items: center;
      padding-left: 12px;

      .avatar {
        display: flex;
        flex-direction: row;
        align-items: center;

        p {
          display: none;
          font-size: 12px;
          padding-right: 8px;
        }

        @media only screen and (min-width: 481px) {
          p {
            display: none;
          }
        }

        @media only screen and (min-width: 1024px) {
          p {
            display: block;
          }
        }

        @media only screen and (min-width: 1280px) {
          p {
            display: block;
          }
        }
      }

      ul {
        list-style: none;
        display: flex;
        flex-direction: row;

        li + li {
          margin-left: 12px;
          border-left: 1px solid #ccc;
          padding-left: 12px;
        }

        li {
          button {
            border: none;
            background: none;
            color: #000;
            font-size: 16px;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        a {
          color: #000;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    div + div {
      margin-left: 4px;
      border-left: 1px solid #ccc;
    }
  }
`;

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-left: 12px;
  padding-right: 4px;
  background: #fff;
`;

export const NotificationList = styled.div`
  display: ${Props => (Props.open ? 'flex' : 'none')};
  flex-direction: column;
  width: 100%;
  max-width: 420px;
  position: absolute;
  z-index: 9;
  top: 60px;
  right: 20px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);

  .loading {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
    padding-top: 6px;
    padding-bottom: 12px;
  }

  button {
    width: 100%;
    background: #fff;
    border: 0px;
    padding: 6px 12px;
    border-radius: 5px;
    text-align: left;
    font-size: 14px;

    p {
      font-size: 11px;
      color: #999;
    }

    &:hover {
      background: #eee;
    }

    + button {
      border-top: 1px solid #eee;
    }
  }
`;
