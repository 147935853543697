import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';

import pt from 'date-fns/locale/pt';
import { format, parseISO } from 'date-fns';

import {
  MdNotifications,
  MdStar,
  MdPerson,
  MdExitToApp,
  MdPets,
} from 'react-icons/md';

import { signOut } from '../../store/modules/auth/actions';
import {
  updateFavoriteRequest,
  updateNotificationRequest,
} from '../../store/modules/user/actions';

import { navigate } from 'gatsby'; // eslint-disable-line

import avatar from '../../images/semavatar.jpg';

import api from '../../services/api';

import { HeaderContainer, NotificationList, Container } from './styles';
import logo from '../../images/logo.svg';
import { Link } from 'gatsby'; // eslint-disable-line
import { BASE } from '../../utils/environament';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledBadge = withStyles(theme => ({
  badge: {
    right: 0,
    top: 2,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);

export default function Header() {
  const [anchorEl, setAnchorEl] = useState(null);
  const profile = useSelector(state => state.user.profile);
  const favourite = useSelector(state => state.user.favorite);
  const notification = useSelector(state => state.user.notification);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const dispatch = useDispatch();
  const url = `${BASE.API}/storage/`;

  const handleToggle = async () => {
    setOpen(!open);
    setLoading(true);
    const { data } = await api.get(
      `notifications?user=${profile && profile.id}&count=`,
    );

    const percorre = data.data.map(item => ({
      ...item,
      created_at: format(
        parseISO(item.created_at),
        "dd 'de' MMMM', às ' HH:mm'h'",
        {
          locale: pt,
        },
      ),
    }));

    setNotifications(percorre);
    setLoading(false);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleSignOut = () => {
    dispatch(signOut());
  };

  const handleNotification = useCallback(async event => {
    if (event.type === 'pet') {
      navigate(`/app/get/adocao/${event.pet.slug}`);
      return;
    }

    navigate(`/app/get/pdf/adocao/${event.pet.slug}`);

    if (!event.read) {
      await api.put(`notification/${event.id}`);
    }
  }, []);

  useEffect(() => {
    async function loadCliente() {
      const response = await api.get(`favourite?user=${profile && profile.id}`);
      const resNoti = await api.get(
        `notifications?user=${profile && profile.id}&count=true`,
      );

      dispatch(updateFavoriteRequest(profile ? response.data : 0));
      dispatch(updateNotificationRequest(profile ? resNoti.data : 0));
    }

    loadCliente();
  }, []);

  return (
    <HeaderContainer>
      <Container>
        <div id="logo">
          <Link to="/">
            <img src={logo} alt="adotapet" />
          </Link>
        </div>
        <div id="barra">
          <div className="icon">
            <IconButton
              aria-label="notification"
              className="favorito"
              onClick={() => navigate('/app/favorito')}
            >
              <StyledBadge badgeContent={favourite} color="secondary">
                <MdStar color="#FFB973" />
              </StyledBadge>
            </IconButton>
            <IconButton aria-label="notification" onClick={handleToggle}>
              <StyledBadge badgeContent={notification} color="secondary">
                <MdNotifications color="#005BB7" />
              </StyledBadge>
            </IconButton>
          </div>
          <NotificationList open={open}>
            {loading ? (
              <div className="loading">
                <CircularProgress size={22} />
              </div>
            ) : (
              <>
                {notifications.map(item => (
                  <button
                    type="button"
                    onClick={() => handleNotification(item)}
                  >
                    {item.title}
                    <p>{item.created_at}</p>
                  </button>
                ))}
              </>
            )}
          </NotificationList>
          <div className="login">
            {profile ? (
              <>
                <span className="avatar">
                  <p>{profile.name}</p>
                  {profile.file ? (
                    <Avatar
                      alt={profile.name}
                      src={`${url}${profile.file.file}`}
                    />
                  ) : (
                    <Avatar alt={profile.name} src={avatar} />
                  )}
                </span>
                <span>
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={() => navigate('/app/editar')}>
                      <ListItemIcon>
                        <MdPerson size={22} color="#000" />
                      </ListItemIcon>
                      <ListItemText primary="Minha Conta" />
                    </MenuItem>
                    <MenuItem onClick={handleSignOut}>
                      <ListItemIcon>
                        <MdExitToApp size={22} color="#000" />
                      </ListItemIcon>
                      <ListItemText primary="Sair" />
                    </MenuItem>
                  </StyledMenu>
                </span>
              </>
            ) : (
              <ul>
                <li>
                  <Link to="/login">Entrar</Link>
                </li>
                <li>
                  <button onClick={handleClick}>Cadastrar</button>
                  <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={() => navigate('/cadastro')}>
                      <ListItemIcon>
                        <MdPerson size={22} color="#000" />
                      </ListItemIcon>
                      <ListItemText primary="Cadastrar como Usuário" />
                    </MenuItem>
                    <MenuItem onClick={() => navigate('/cadastro-ong')}>
                      <ListItemIcon>
                        <MdPets size={22} color="#000" />
                      </ListItemIcon>
                      <ListItemText primary="Cadastrar como ONG" />
                    </MenuItem>
                  </StyledMenu>
                </li>
              </ul>
            )}
          </div>
        </div>
      </Container>
    </HeaderContainer>
  );
}
